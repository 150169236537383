.herocontact-wrapper2 {
  padding: 180px 20px; 
  background-image: url("../../assets/imgs/fuundo.jpg");
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center; 
  position: relative;
}

.herocontact-overlay2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
}

.herocontact-container2 {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  line-height: 0;
  padding: 20px;
}

.herocontact-content {
  max-width: 800px;
  margin: 0 auto; /* Center content */
  padding: 20px; /* Add some padding around the content */
}

.texts-herocontact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1.5s ease-in-out; /* Add fade-in animation */
}

.texts-herocontact h1 {
  margin-top: 40px;
  font-family: "Cinzel", serif;
  font-size: 45px; /* Slightly reduced font size for better balance */
  font-weight: 400; /* Increased font weight for stronger emphasis */
  color: #ffffff; /* White color for better contrast */
  margin-bottom: 20px;

}

.texts-herocontact h2 {
  font-family: "Cinzel", serif;
  font-size: 30px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 15px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

.texts-herocontact p {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  line-height: 1.5;
  color: #ffffff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3); /* Add subtle text shadow */
}

@media (max-width: 768px) {
  .herocontact-wrapper2 {
    padding: 200px 20px; /* Adjusted padding for smaller screens */
  }

  .herocontact-content {
    padding: 10px; /* Reduced padding for smaller screens */
  }

  .texts-herocontact h1 {
    font-size: 40px; /* Adjusted font size for better readability */
  }

  .texts-herocontact h2 {
    font-size: 24px;
  }

  .texts-herocontact p {
    font-size: 16px;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
