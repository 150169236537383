.alltreatments-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background-image: url("../../assets/imgs/fundopuro.jpg");
  text-align: center;
}

.title-services {
  text-align: center;
}

.alltreatments-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.title-services,
.alltreatments-content {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  margin: auto;
  gap: 30px;
  margin-bottom: 30px;
}

.title-services h1 {
  font-family: "Cinzel";
  font-size: 45px;
  font-weight: 200;
}

.title-services p,
h5 {
  font-family: "Cinzel";
  font-size: 20px;
  font-weight: 400;
}

.alltreatments-img {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.alltreatments-content p {
  max-width: 800px;
  line-height: 1.5;
  font-size: 18px;
  color: #7b7b7b;
  font-family: "Open Sans";
}

.alltreatments-img .image-container {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  flex: 0 0 calc(25% - 20px);
  max-width: 100%;
}

img.recebido {
  max-width: 400px !important;
  border-radius: 20px;
}
.img-meninas{
  max-width: 600px;
}
.alltreatments-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s;
}

.alltreatments-img .image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
  border-radius: 10px;
}

.alltreatments-img .image-container:hover .image-overlay {
  opacity: 1;
}

.image-title {
  font-family: "Cinzel";
  font-size: 18px;
  text-align: center;
  color: white;
}

.treatment-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  width: 100%;
  padding: 30px 0;
}

.treatment-card {
  position: relative;
  background-image: url("../../assets/imgs/fundopuro.jpg");
  background-size: cover;
  background-position: center;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.treatment-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.treatment-title {
  position: relative;
  z-index: 2;
  font-family: "Cinzel";
  font-size: 18px;
  color: white;
}
