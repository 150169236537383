@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.Texto2-container {
  /* background-image: url("../../assets/imgs/fundo.jpg"); */
  background-color: #000000cd !important;
  width: 100%;
  overflow: hidden; /* Esconde o texto que está fora dos limites do contêiner */
  white-space: nowrap; /* Impede a quebra de linha */
  box-sizing: border-box;
  background-color: white;
  z-index: 1;
}

.Texto2-content {
  padding: 8px; /* Reduzido o padding */
  display: flex; /* Necessário para a animação funcionar */
  gap: 5px; /* Reduzido o gap */
  width: 200%; /* Duplicar a largura para permitir rolagem contínua */
  animation: scroll 35s linear infinite; /* Animação linear e infinita */
}

.Texto2-content span {
  font-family: "Cinzel";
  font-weight: 400;
  margin-left: 5px; /* Reduzido o margin-left */
  font-size: 13px; /* Reduzido o tamanho da fonte */
  padding: 4px; /* Reduzido o padding */
  letter-spacing: 0.05rem;
  display: inline-block; /* Necessário para a animação funcionar */
  width: 50%; /* Cada span ocupa metade da largura do contêiner */
  color: #fff;
  align-items: center;
}


.img-mkt2 {
  margin-right: 20px; /* Reduzido o margin-right */
}

.Texto2-content img {
  max-width: 25px;
  vertical-align: middle; /* Alinha verticalmente as imagens ao texto */
  margin-left: 5px; /* Reduzido o margin-left */
}

@media (max-width: 768px) {
  .img-mkt2 {
    margin-right: 5px; /* Reduzido o margin-right para telas menores */
  }
  
  .Texto2-content {
    padding: 2px; /* Reduzido o padding ainda mais para telas menores */
    gap: 2px; /* Reduzido o gap para telas menores */
  }
  
  .Texto2-content span {
    margin-left: 5px; /* Mantido o margin-left reduzido */
    font-size: 12px; /* Reduzido o tamanho da fonte para telas menores */
    letter-spacing: 0.05rem;
    display: inline-block; /* Necessário para a animação funcionar */
    width: 50%; /* Cada span ocupa metade da largura do contêiner */
    color: black;
    align-items: center;
  }
}
