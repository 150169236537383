.home-wrapper {
  padding: 150px 20px;
  padding-top: 200px;
  background-image: url(../../assets/imgs/fundoccinza.jpg);
  background-repeat: no-repeat;
}

.home-container {
  max-width: 1100px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.home-container div {
  flex: 1;
}

.home-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  animation: slideLeft 3s forwards; /* Animation for sliding in from left */
}

.home-content h2 {
  font-family: "Cinzel";
  font-size: 38px;
  font-weight: 500;
  color: #393738;
}

.home-content h5 {
  font-family: "Cinzel";
  font-size: 22px;
  font-weight: 500;
  color: #7b7b7b;
}

.home-content p {
  font-family: "Open Sans";
  font-size: 18px;
  line-height: 1.3;
  color:  #9e9e9e;
}

.home-img {
  background-image: url('');
  border-radius: 30px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 400px;
  max-height: 400px;
  animation: slideRight 3s forwards;   
}

.home-video {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
}

@media (max-width: 768px) {
  .home-container {
    padding-top: 10px;
    flex-direction: column;
  }

  .home-wrapper {
    padding: 150px 20px;
    padding-bottom: 20px;
  }

  .home-content h2 {
    font-family: "Cinzel";
    font-size: 28px;
    font-weight: 500;
    color: #393738;
  }

  .home-content h5 {
    font-family: "Cinzel";
    font-size: 20px;
    font-weight: 500;
    color: #7b7b7b;
  }

  .home-content p {
    font-family: "Open Sans";
    font-size: 15px;
    line-height: 1.3;
  }
  .home-img {
    animation: slideRight 3s forwards;   
  }

  iframe {
    width: 400px;
    height: 300px;
  }
}

/* Keyframe animations */
@keyframes slideLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}