.mapa {
    line-height: 0;
  }
  
  .mapa-container {
    line-height: 0;
  }
  
  @media (max-width: 768px) {
    .mapa {
      height: 200px;
      margin-right: 30px !important;
      width: 100% !important; /* Garante que o mapa ocupe toda a largura disponível */
    }
  }
  