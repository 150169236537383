.allservices-wrapper {
  position: relative;
  padding: 90px 60px;
  background-image: url("../../assets/imgs/fundopuro.jpg");
}

.allservices-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.allservices-container {
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
}

.allservices-content {

  text-align: center;
}

.texts-allservices {
  margin-bottom: 20px;
}

.texts-allservices h1,
.texts-allservices h2 {
  margin: 10px 0;
}

.imgs-services {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 10px;
  justify-items: center;
}

.imgs-services img {
  max-width: 400px;
  width: 100%;
  height: auto;
  border-radius: 8px;
}