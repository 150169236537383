.instaFeed-wrapper {
  padding: 100px 20px;
  padding-bottom: 50px;
  background-image: url("../../assets/imgs/fundopuro.jpg");
}

.instaFeed-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  max-width: 1250px;
  margin: auto;
  justify-content: center
}

.instaFeed-content div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.instaFeed-container h2 {
  font-family: "Cinzel";
  font-weight: 400;
  text-align: center;
  font-size: 55px;
  color: #393738;
  margin-bottom: 8px;
}
.instaFeed-container h4 {
  font-family: "Cinzel";
  font-weight: 400;
  text-align: center;
  font-size: 20px;
  color: #393738;
  margin-bottom: 40px;
}

.instaFeed-content p {
  color: #333;
  font-size: 18px;
  line-height: 1.3;
}

.instaFeed-image {
  width: 400px;
  height: 400px;
  background-color: #ddd;
  border-radius: 50%;
}

/* Media Queries */
@media (max-width: 1024px) {
  .instaFeed-container {
      flex-direction: column;
      align-items: center;
  }
  .instaFeed-image {
      width: 300px;
      height: 300px;
  }
  .instaFeed-content h3 {
      font-size: 26px;
  }
  .instaFeed-content p {
      font-size: 16px;
  }
  .instaFeed-container h2 {
    font-size: 40px;
   
    margin-bottom: 8px;
  }
  .instaFeed-container h4 {
   
    font-size: 16px;
   
  }
}

@media (max-width: 768px) {
  .instaFeed-content h3 {
      font-size: 22px;
  }
  .instaFeed-content p {
      font-size: 14px;
  }
  .instaFeed-image {
      width: 250px;
      height: 250px;
  }
}

@media (max-width: 480px) {
  .instaFeed-content h3 {
      font-size: 18px;
  }
  .instaFeed-content p {
      font-size: 12px;
  }
  .instaFeed-image {
      width: 200px;
      height: 200px;
  }
}
