.header-wrapper.scrolled {
  position: fixed;
  background-repeat: repeat;
  background-image:url('../../assets/imgs/marmore.jpg');
  box-shadow: none;
  border-bottom: none;
  transition: 0.3s;
}
.header-img{
  max-width: 90px;
}
.header-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 101;
  width: 100%;

  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  transition: 0.3s;
}

header {
  min-height: calc(90px - 41px);
  /* header - padding */
  padding: 0 55px !important;
  display: flex;
  align-items: center;
  z-index: 100;
  margin: auto;
  height: 100%;
  gap: 20px;
  justify-content: space-between;
  max-width: 1250px;
}

.nav-container,
nav ul {
  display: flex;
  gap: 25px;
  list-style: none;
}

nav ul li button {
  background: none;
  font: inherit;
  font-weight: 550;
  font-size: 18px;
  font-family: "Open Sans";
  cursor: pointer;
  color: #131313 !important;
  position: relative; /* Necessário para posicionar o pseudo-elemento */
  transition: transform 0.3s ease; /* Transição suave para a transformação */
}

nav ul li button::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -5px;
  width: 0;
  height: 1px; 
  background-color: #131313; 
  transition: width 0.3s ease, left 0.3s ease; 
}

nav ul li button:hover {
  transform: scale(1.1); 
}

nav ul li button:hover::after {
  width: 100%; 
  left: 0; 
}

.header h3:hover {
  font-family: 'Cinzel', serif;
  font-size: 1.5rem;
  font-weight: 400;
  color: #7b7b7b;
  margin-top: 0;
  margin-bottom: 30px;
  letter-spacing: 0.5px;
  text-decoration: underline;
  text-decoration-color: #7b7b7b;
  text-decoration-thickness: 1px;
  text-underline-offset: 10px;
}
.social-links {
  display: flex;
  gap: 10px;
}

.social-links i {
  color: #7c7c7c;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.header-wrapper nav ul li button::after{
  background-color: #fff;
}

.header-wrapper.scrolled nav ul li button::after{
  background-color:  #7c7c7c !important;
}

.header-wrapper nav ul li button::after,
.header-wrapper .social-links i,
.header-wrapper nav ul li button{
  color: #fff !important;
}

.header-wrapper.scrolled .social-links i,
.header-wrapper.scrolled nav ul li button{
  color:  #7c7c7c !important;
}
.nav-container{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
/* Estilos para telas menores (mobile) */
@media (max-width: 768px) {
header{
  padding: 0 20px !important;
}

  .nav-container {
    display: none;
  }

  .menu-toggle {
    display: block;
  }
}


@media (max-width: 1240px) {
  nav{
    display: none;
  }

  .nav-container a{
    display: none;
  }
  .menu-toggle {
    display: block;
  }
}