/* Estilo para o componente FacialTreatmentsList */
.facial-treatments-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-family: "Open Sans";
    background-image: url("../../assets/imgs/fundopuro.jpg");
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .list-title{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .list-title h1{
    font-family: "Cinzel", serif;
    font-size: 36px;
    font-weight: 500;
    color: #000;
    margin-bottom: 15px;
  }
  .list-title h4{
    font-family: "Cinzel", serif;
    font-size: 24px;
    font-weight: 500;
    color: #3c3c3c;
    margin-bottom: 15px;
  }


  /* Estilo para cada item de tratamento */
  .treatment-item2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  /* Estilo para o nome do tratamento */
  .treatment-name2 {
    font-weight: bold;
    font-size: 16px;
    color: #676767;
  }
  
  /* Estilo para o preço do tratamento */
  .treatment-price2 {
    font-size: 20px;
    font-weight: 600;
    color: #7b7b7b;
  }

  @media (max-width: 768px) {
  
    .list-title h1{
     
      font-size: 30px;
      text-align: center;
      margin-bottom: 15px;
    }
    .list-title h4{
      font-size: 22px;
      text-align: center;
      margin-bottom: 15px;
    }
    .main-btn.alt {
      text-align: center;
  }
  
  
  
  }
  