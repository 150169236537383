.cta2-wrapper {
  padding: 20px 20px;
}

.cta2-container {
  
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.cta2-container div {
  flex: 1;
}

.cta2-content {
  display: flex;

}
.cta2-content img {
  border: 1px solid rgba(255, 255, 255, 0); /* Manter a borda invisível como está */
  box-shadow: 0 4px 2px rgba(0, 0, 0, 0.1); /* Sombra elegante */
  transition: box-shadow 0.3s ease; /* Transição suave */
  padding: 0 20px;
}

.cta2-content img:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Sombra maior ao passar o cursor */
  cursor: pointer; /* Alterar o cursor para pointer */
}

.cta2-content h2 {
  font-size: 34px;
  color: #212a34;
}

.cta2-content p {
  font-size: 18px;
  line-height: 1.3;
}

.cta2-img {
  background-image: url('');
  border-radius: 30px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  background-color: #212a34;
  min-height: 400px;
  max-height: 400px;
}

@media (max-width: 768px) {
  .cta2-container {
    padding-top: 10px;
    flex-direction: column;
  }
  .cta2-content {
    padding-top: 10px;
    flex-direction: column;
  }

  .cta2-wrapper {
    padding: 30px 20px;
  }
}
