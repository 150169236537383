.aboutkeila-wrapper {
  background: linear-gradient(to bottom, #9e9e9e99, #393738a6);
  padding: 90px 20px;
}

.aboutkeila-container {
  max-width: 1170px;
  margin: auto;
  display: flex;
  flex-direction: column; /* Adjusted for better responsiveness */
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.aboutkeila-container h2 {
  font-family: "Cinzel";
  font-weight: 200;
  font-size: 45px;
  color: #000;
}

.aboutkeila-container h5 {
  font-family: "Cinzel";
  font-weight: 400;
  font-size: 28px;
  color: #747474;
  margin-bottom: 40px;
}

.keila-bio {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.biokei {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.aboutkeila-content {
  display: flex;
  flex-direction: column; /* Adjusted for better responsiveness */
  align-items: center;
  justify-content: center;
  max-width: 80%;
  gap: 15px;
}

.biokei {
  max-width: 600px;
}

.aboutkeila-content p {
  font-family: "Open Sans";
  font-size: 20px;
  text-align: left;
  line-height: 1.3;
  color: #eaeaea;
}

.aboutkeila-img {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 1px rgba(0, 0, 0, 0.19);
}

.aboutkeila-img img {
  width: 100%; /* Adjusted for better responsiveness */
  max-width: 600px;
  border-radius: 10px;
}

@media (min-width: 768px) {
  .aboutkeila-container {
    flex-direction: row; /* Revert to row layout for larger screens */
  }

  .aboutkeila-content {
    text-align: left; /* Align text to left for larger screens */
  }

  .aboutkeila-img img {
    max-width: 400px;
  }
}

@media (max-width: 768px) {
  .aboutkeila-wrapper {
    padding: 60px 20px;
  }
  .aboutkeila-container h2 {

    font-size: 40px;
    text-align: center;
    margin-bottom: 5px;

  }
  
  .aboutkeila-container h5 {
    text-align: center;
    font-size: 22px;
    margin-bottom: 40px;
  }
  .aboutkeila-content p {
    font-size: 18px;
  }
  .aboutkeila-container {
    gap: 20px; /* Increase gap between elements on smaller screens */
  }

  .aboutkeila-img {
    flex-direction: column;
    justify-content: space-around; /* Adjust spacing between images on smaller screens */
  }

  .aboutkeila-content {
    max-width: 100%; /* Adjust max width of content on smaller screens */
  }

  .aboutkeila-img img {
    display: flex;
    align-items: center; 
    justify-content: center; 
    max-width: 90%; /* Ensure image is responsive on smaller screens */
  }
}
