.especialidades-wrapper {
  padding: 20px;
  background-color: #eaeaea;
}

.especialidades-container {
  max-width: 1150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: auto;
  padding: 0 20px;
}
.header {
  text-align: center;
  margin-bottom: 40px; /* Espaçamento abaixo do cabeçalho */
}

.header h1 {
  margin-top: 30px;
  font-family: 'Cinzel', serif;
  font-size: 3rem; /* Tamanho grande para o título */
  font-weight: 400;
  color: #333; /* Cor escura para contraste */
  margin-bottom: 10px; /* Espaçamento abaixo do título */
  letter-spacing: 1px; /* Espaçamento entre letras para elegância */
}

.header h3 {
  font-family: 'Cinzel', serif;
  font-size: 1.5rem;
  font-weight: 400;
  color: #7b7b7b;
  margin-top: 0;
  margin-bottom: 20px;
  letter-spacing: 0.5px;
  text-decoration: underline;
  text-decoration-color: #7b7b7b; /* Cor do underline, pode ajustar se necessário */
  text-decoration-thickness:1px; /* Espessura do underline */
  text-underline-offset: 10px; /* Ajuste a distância do underline em relação ao texto */
}


.treatments-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); /* Ajuste o minmax conforme necessário */
  gap: 20px; /* Espaçamento entre os itens do grid */
}

.card {
  margin-top: 50px;
background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.treatment-name {
  font-family: 'Cinzel', sans-serif;
  font-size: 22px;
  text-align: center;
  color: #2b2a2a;
  font-weight: 500;
  margin-bottom: 10px;
}

.treatment-description {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  font-weight: 300;
  color: #838383;
  font-family: "Open Sans", sans-serif;
  margin: 10px 0;
}

.treatment-duration,
.treatment-price {
  font-family: 'Cinzel', sans-serif;
  font-size: 22px;
  text-align: center;
  color: #7b7b7b;
  margin-top: 10px;
}

.form-section {
  display: flex;
  justify-content: space-between;
  background: #f9f9f9;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: auto;
  gap: 20px;
}

.formflex2 {

  display: flex;
  justify-content: center;
  align-items: center;
}

.img-large {
  border-radius: 25px;
  max-width: 550px;
}

.formflex {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.formflex h2 {
  font-family: 'Cinzel', sans-serif;
  font-size: 28px;
  color: #7b7b7b;
  font-weight: 400;
  margin-bottom: 20px;
}

.text-form p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #2d2c2c;
  line-height: 1.5;
  margin-bottom: 10px;
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

label {
  font-family: "Open Sans";
  font-size: 12px;
  color: #333;
  margin-bottom: 10px;
  width: 100%;
}

input, textarea {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  color: #333;
}

textarea {
  resize: vertical;
  height: 100px;
}

button[type="submit"] {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #848484;
}

button.whatsapp-msg {
  background-color: rgb(73, 73, 73);
  border-radius: 10px;
  color: #f7f7f7;
  cursor: pointer;
  font-size: 18px;
  padding: 15px 45px;
  transition: 0.3s;
  display: block;
  width: fit-content;
  margin: auto;
}

button.whatsapp-msg:hover {
  background-color: #9c8a72c6;
}

@media (max-width: 768px) {
  .form-section {
    flex-direction: column;
  }

  .formflex h2 {
    margin-top: 20px;
    font-size: 24px;
  }

  .formflex {
    padding-left: 0;
  }

  .treatments-grid {
    grid-template-columns: 1fr; /* 1 item por linha em telas menores */
  }
}
