.testimonials-wrapper {
  padding: 90px 20px;
  background-color: #eaeaea;
}

.testimonials-container {
  max-width: 1170px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.testimonials-container div {
  flex: 1;
}

.testimonials-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.testimonials-content h2 {
  font-size: 50px;
  font-weight: 400;
  font-family: "Cinzel";
  color: #393738;
}

.testimonials-content p {
  font-size: 18px;
  font-family: "Cinzel";
  text-align: center;
  color: #9e9e9e;
  line-height: 1.3;
}

.testimonials-img{
  display: flex;
  gap: 20px;
  
}

.testimonials-img img{
  max-width: 280px;
  box-shadow: 0px 5px 15px 0px
  rgba(5, 5, 57, 0.418);
  border-radius: 10px;
  
}
.bold-text {
  font-weight: bold;
}
@media (max-width: 768px) {
  .testimonials-container {
    padding-top: 10px;
    flex-direction: column;
  }

  .testimonials-wrapper {
    padding: 60px 20px;
  }
  .testimonials-img{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .testimonials-content h2 {
    font-size: 40px;

  }
  
}
