.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background-image: url("../../assets/imgs/fundopuro.jpg");
    max-width: 600px;
    padding: 15px;
    border-radius: 8px; /* Bordas arredondadas */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Sombra suave */
    position: relative;
    overflow: hidden;
    margin-top: 60px;
    z-index: 1000;
  }
  
  .modal-content {
    text-align: center;
    z-index: 1000;
  }
  
  .modal-content h2 {
    font-family: "Cinzel";
    font-size: 28px;
    font-weight: 500;
    color: #0b0b0b;
  }
  
  .modal-content p {
    font-family: "Open Sans";
    max-width: 700px;
    font-size: 16px;
    line-height: 1.3;
    color:  #000000;
  }
  
  .modal-content form {
    margin-top: 20px;
  }
  
  .modal-content label {
    font-family: "Cinzel";
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .modal-content input,
  .modal-content select {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .modal-content button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #aeaeae;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .modal-content button:hover {
    background-color: #636363;
  }
 
  .modal-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px !important;
  }
  .img-modal{
    max-width:350px !important;
    border-radius: 20px;
  }