@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Cinzel";
  src: url("./assets/fonts/Cinzel-Regular.ttf") format("opentype");
  font-weight: 500 !important;
}

.App {
  font-family: sans-serif;
}

.whats-btn a, .whats-btn2 a {
    border-radius: 99px;
    bottom: 2rem;
    font-size: 60px;
    opacity: 1;
    padding: 10px;
    position: fixed;
    right: 2rem;
    transition: 0.4s;
    z-index: 50;
}

.whats-btn a {
    background-color: #393738;
    color: #d6cdc1;
}

.whats-btn a:hover {
    background-color: #d6cdc1;
    color: #393738;
}

.whats-btn2 a {
    background-color: #37ff0f;
    color: #ffffff;
    margin-bottom: 90px;
}

.whats-btn2 a:hover {
    background-color: #f1f1f1;
    color: #37ff0f;
}

.whats-btn {
    display: block;
}

@media (min-width: 768px) {
  .whats-btn {
    display: none;
  }
}
