/* Estilos para o wrapper principal */
.contact-wrapper {
  background-image: url("../../assets/imgs/fundopuro.jpg");
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

/* Container do conteúdo */
.contact-container {
  width: 90%;
  max-width: 1200px;
  margin: auto;
}

/* Estilos para o conteúdo principal */
.contact-content {
  text-align: center;
}

.contact-content h2 {
  font-family: "Cinzel", serif;
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  color: #000;
  margin-bottom: 15px;
}

.contact-content p {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  margin-bottom: 15px;
}

/* Estilos para as informações de contato */
.contact-infos {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact-infos h3 {
  font-family: "Cinzel", serif;
  font-weight: 400;
  font-size: 30px;
  color: #000;
}

.contact-infos a {
  font-family: "Cinzel", serif;
  font-weight: 500;
  font-size: 20px;
  color: #000;
}

/* Estilos para os botões e links */
.MainBtn {
  margin-top: 20px;
}

/* Estilos para o slider de imagens */
.contact-img {
  margin-top: 20px;
}

.image-slide img {
  width: 100%;
  height: auto;
}

/* Estilos para os ícones de redes sociais */
div.social-links2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-left: 34px;
}
.hours-section p{
font-family:  "Cinzel";
font-size: 14px;
  
}
.social-links2 .follow h3 {
  font-family: "Cinzel", serif;
  font-size: 24px;
  color: #000;
  margin-bottom: 10px;
}

.social-links2 .icons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.social-links2 .icons a {
  color: #000;
  font-size: 24px;
}

.social-links2 .icons a i {
  transition: color 0.3s ease;
}

.social-links2 .icons a i:hover {
  color: #666;
}

/* Mídia queries para tornar responsivo */
@media (max-width: 1024px) {
  .contact-infos h3 {
    font-size: 28px;
  }
}

@media (max-width: 768px) {
  div.contact-wrapper{
    padding:30px ;
  }
  div.contact-wrapper h2{
    font-size: 30px;

  }
  .contact-content h2 {
    font-size: 30px;
  }

  .contact-content p {
    font-size: 18px;
    font-family: "Open Sans";
    text-align: center;
  }
  .location-section a{
    font-size: 18px;
    font-family: "Open Sans";
    text-align: center;
  }
  .location-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .contact-infos {
    flex-direction: column;
    align-items: flex-start;
  }

  .contact-infos h3 {
    font-size: 20px;
  }

  .social-links2 .follow h3 {
    font-size: 20px;
  }

  .social-links2 .icons a {
    font-size: 20px;
  }
  .social-links2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .contact-content h2 {
    font-size: 24px;
    text-align: center;
  }

  .contact-content p {
    font-size: 16px;
  }

  .social-links2 .follow h3 {
    font-size: 18px;
  }

  .social-links2 .icons a {
    font-size: 18px;
  }
}