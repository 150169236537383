.footer-background {
  background-color: #222;
  color: #ddd;
  padding: 100px 20px;
  padding-bottom: 40px;
  font-family: "Open Sans";
  z-index: 100;
  position: relative;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1300px;
  margin: auto;
  gap:60px;
}

.footer-container > div{
  flex: 1;
}

.footer-logo {

}

.footer-item h6{
  color: #d1d1d1;
  line-height: 1.6;
  font-size: 14px;
  padding: 10px 0;
}

.footer-logo h1 {
  font-size: 32px;
  color: #fff;
  margin-bottom: 20px;
}

.footer-logo p {
  font-size: 16px;
  line-height: 1.6;
  color: #bbb;
}

.footer-socials a {
  color: #fff;
  margin: 0 10px;
  font-size: 24px;
  transition: color 0.3s ease;
}

.footer-socials a:hover {
  color: #007bff;
}

.footer-item {
  margin-bottom: 20px;
}

.footer-item h5 {
  font-size: 18px;
  margin-bottom: 20px;
  height: 32px;
  text-transform: uppercase;
  color: #fff;
  font-family: "Cinzel";
}

.footer-item div,
.footer-item p {
  font-size: 14px;
  line-height: 1.6;
  color: #bbb;
}

.footer-item .especialidades p{
  margin-bottom: 15px;
}

.footer-item li {
  list-style: none;
  margin-bottom: 15px;
}

.footer-item button {
  background: none;
  border: none;
  cursor: pointer;
  line-height: 1.6;
  font-size: 14px;
  text-align: left;
  padding: 0;
  transition: color 0.3s ease;
  
  color: #fff;
}

.footer-item button:hover {
  color: #fff;
}

.footer-copy {
  max-width: 1300px;
  margin: auto;
  text-align: center;
  padding: 20px 0;
  border-top: 1px solid #444;
  margin-top: 40px;
  font-size: 14px;
  color: #aaa;
  display: flex;
  justify-content: space-between;

}
.footer-logo img{
  max-width: 100px;
  
}
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    gap: 20px;
  }

  .footer-container > div {
    width: 100%;
  }

  .footer-copy {
    flex-direction: column;
    gap: 10px;
    padding: 20px 0;
  }
}

