.faq-wrapper{
    background-color: transparent !important;
}

.faq-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 60px 20px;
    font-size: 20px;
    text-align: center;

  }

  
  .faq-container h2 {
    font-family: "Cinzel";
    font-size: 36px;
    font-weight: 500;
    color: #000;
    margin-bottom: 15px;


  }
  
  .faq-list {
    margin-top: 20px;
  }
  
  .faq-item {
    border: 1px solid #ddd;
    color: #A66832;
    margin-bottom: 15px;
    overflow: hidden;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
  }
  
  .faq-question {
    flex: 1;
    cursor: pointer;
    padding: 15px;
    background-color: #d8d8d8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease;
    border-radius: 15px;
  }
  
  .question-text {
    font-family: "Cinzel";
    color: #000;
    flex-grow: 1;
  }
  
  .toggle-icon {
    font-size: 18px;
    font-family: "Cinzel";
    transition: transform 0.3s ease;
  }
  
  .faq-answer {
    color: #000;
    font-family: "Open Sans";
    padding: 15px;
    display: none;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    max-height: 0;
    overflow: hidden;
  }
  
  .faq-answer.show {
    display: block;
    max-height: 500px; /* Ajuste conforme necessário */
    opacity: 1;
  }
  
@media (max-width: 768px) {
  
  .toggle-icon {
    font-size: 14px;
  }
  div.question-text{
    font-size: 16px;
  }
  .faq-container h2 {

    font-size: 30px;

}


}