.sidebar {
  position: absolute;
  top: 0;
  right: 0;
  width: 340px;
  height: 100vh;
  padding: 50px;
  background-color: #fff;
  z-index: 100;
  z-index: 1000 !important;
}

.sidebar .social-links i {
  color: #565656 !important;
}

.sidebar .main-btn.alt{
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;

}
.menu-toggle img {
  width: 100%;
  height: auto;
  max-width: 40px !important;
}

.close-menu-btn {
  font-family: "Cinzel";
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 30px;
  background-color: transparent;
  color: #666666;
}

.close-menu-btn img {
  max-width: 30px !important;
}

.sidebar a,
.sidebar button {
  font-family: "Open Sans";
  display: block;
  height: 46px;
  line-height: 46px;
  cursor: pointer;
  color: #565656 !important;
  background-color: transparent;
  font-size: 20px;
  text-transform: none !important;
  font-weight: 400;
}

.curtain {
  font-family: "Cinzel";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
.follow{
  font-family: "Cinzel";
  font-size: 14px;
  font-weight: 500;
  color: #393738;

}
.sidebar-socials {
  font-family: "Cinzel";
  position: absolute;
  bottom: 50px;
  left: 50px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
}

