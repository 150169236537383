.main-btn {
  background-color: rgb(73, 73, 73);
  border-radius: 10px;
  color: #f7f7f7;
  cursor: pointer;
  font-size: 18px;
  padding: 15px 45px;
  transition: 0.3s;
  display: block;
  width: fit-content;
}
.main-btn2 {
  background-color: #d6cdc1;
  border-radius: 10px;
  color: #777;
  cursor: pointer;
  font-size: 18px;
  padding: 15px 45px;
  transition: 0.3s;
  display: block;
  margin: auto;
  margin-top: 40px;
  width: fit-content;
}


.main-btn:hover {
  background-color: grey;
  transform: scale(1.05);
}

.main-btn.alt {
  background-color: #d6cdc1;
  color: #393738;

}

.main-btn.alt:hover {
  background-color: #cbb9a1;
}
