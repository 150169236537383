.appointment-wrapper {
    padding: 100px 20px;
    background-image: url("../../assets/imgs/appointment.jpg");
    background-repeat: no-repeat;
}
  
  .appointment-container {
    max-width: 1170px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 40px;
  }
  
  
  .appointment-content {
    display: flex;
    justify-content: space-between;
  }
  
  .appointment-content h2 {
    font-family: "Cinzel";
    font-weight: 400;
    font-size: 34px;
    color: #212a34;
  }
  .appointment-content h4 {
    font-family: "Cinzel";
    font-weight: 400;
    font-size: 20px;
    color: #212a34;
  }
  .appoint-titles{
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  button.appoint-btn {
    font-family: "Cinzel", serif;
    max-width: fit-content;
    padding: 20px 20px;
    background-color: #cbb9a1;
    border-radius: 20px;
    font-size: 20px;

    color: #000;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  button.appoint-btn:hover {
    background-color: #d6cdc1;
    transform: scale(1.05);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }
  
  .appointment-content p {
    font-size: 18px;
    text-align: center;
    line-height: 1.3;
  }
  
  .appointment-img {
    background-image: url('');
    border-radius: 30px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    background-color: #212a34;
    min-height: 400px;
    
    max-height: 400px;
  }
  
  @media (max-width: 768px) {
    .appointment-container {
      padding-top: 10px;
      flex-direction: column;
    }
    .appointment-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        justify-content: space-between;
      }
    .appointment-wrapper {
      padding: 100px 20px;
    }
    button.appoint-btn{
        margin-top: 20px;
    }
  }
  